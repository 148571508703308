import React, { useEffect } from "react"
import useNavigate from '@/hooks/useNavigate'

const Index = () => {

  useEffect(() => {
    useNavigate('/support');
  }, []);

  return null;
}

export default Index
